/* Para personalizar una demo según cliente cambiar los códigos de estos colores y en theme*/

:root {
  --primary-color: #0f6242 ;
  --secondary-color: #000;
  --principalDarker: #393939;
  --background-color: #ffffff;
  --principalLighter: #bebebe; /* Fondo del stepper */
  --fuenteImpact: 'Oswald', sans-serif;
  --fuentePrincipal: 'avenir', sans-serif;

}


p{
  font-size: 13px;
  line-height: 22px;
}
h4 {
  font-size: 18px !important;
  line-height: 29px !important;
}
h3{
  font-size: 21px !important;
  line-height: 34px !important;
}
h2{
  font-size: 32px !important;
  line-height: 54px !important;
}
h1{
  font-size: 57px !important;
  line-height: 92px !important;
}
.principalBackground {
  background-color: var(--primary-color) !important;
}

.secondaryBackground {
  background-color: var(--secondary-color) !important;
}
.setBackgroundImage {
  background-image: url("../images/fondoNatalia.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}
.setBackground {
  background-color: var(--background-color) !important;
}
.principalDarkerBackground {
  background-color: var(--principalDarker) !important;
}
.principalLighterBackground {
  background-color: var(--principalLighter) !important;
}

.disabledBackground {
  background-color: #afafaf !important;;
}

.colorPrincipal {
  color: var(--primary-color);
}
.colorSecundario {
  color: var(--secondary-color);
}
.colorBlanco {
  color: #ffffff !important;
}
.fondoBlanco{
  background-color: #ffffff;
}
.lighterWeight {
  font-weight: lighter;
}
.hidden-router {
  flex: 1 1 auto;
  display: flex;
  overflow: hidden;
}
.centrado {
  -ms-display: flex;
  display: flex;
  /* alineacion vertical */
  align-items: center;
  /* alineacion horizontal */
  justify-content: center;
  vertical-align: middle !important;
  text-align: center !important;
}
.fontItalic {
  font-style: italic;
}
.imgLogoMini {
  width: 40% !important;
  height: 100%;
  object-fit: cover;

}

img.imgLogoParque {
  width: 20%;
  margin: 20px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 8px 0;
}


p {
  margin: 0;
}

.spacer {
  flex: 1;
}


.content {
  display: flex;
  margin: 32px auto;
  padding: 0 16px;
  max-width: 960px;
  flex-direction: column;
  align-items: center;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 16px;
  width: 90%;
}

.borderNone {
  border: none !important;
}
.padding50 {
  padding: 50px;
}
.padding10 {
  padding: 10px !important;
}
.padding30 {
  padding: 30px;
}
.espDer15 {
  margin-right: 15px;
}
.colorRojo{
  color: #dc143c;
}

.card {
  border-radius: 4px;
  border: none !important;
  margin: 16px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  line-height: 18px;

}
.width80 {
  width: 80%;
}
.width90 {
  width: 90%;
}
.card-container .card:not(:last-child) {
  margin-right: 0;
}

.card.card-small {
  height: 16px;
  width: 168px;
}

.card-container .card:not(.highlight-card) {
  cursor: pointer;
}

.card-container .card:not(.highlight-card):hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 17px rgba(black, 0.35);
}

.card-container .card:not(.highlight-card):hover .material-icons path {
  fill: rgb(105, 103, 103);
}

.fullWidth {
  overflow: hidden !important;
  width: 100% !important;
}
.card.highlight-card {
  background-color: #1976d2;
  color: white;
  font-weight: 600;
  border: none;
  width: auto;
  min-width: 30%;
  position: relative;
}

.fullHeight {
  /*width: calc(100vw);*/

  height: calc(100vh);
}

.card.card.highlight-card span {
  margin-left: 60px;
}

a,
a:visited,
a:hover {
  color: #1976d2;
  text-decoration: none;
}

a:hover {
  color: #125699;
}

body{
  font-size: 11px !important;
  font-family: var(--fuentePrincipal) !important;

}
.botonID {
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  border-radius: 8px;
  background-color: var(--primary-color) !important;
  color: #FFF !important;
  font-size: larger !important;
  letter-spacing: 1px;
  font-weight: bold !important;
}

.finalButton {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: 3px var(--primary-color) solid;
  padding: 8px;
}
.desaparecer {
  display: none !important;
}

.alignLeft {
  text-align: left !important;
}
.slide-in-top {
  -webkit-animation: slide-in-top 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-top 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.text-link {
  font-size: 20px;
}
/* ----------------------------------------------
 * Generated by Animista on 2020-9-16 18:18:41
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@media (orientation: landscape) {
  .cameraPreview {
    width: 100%;
    position: fixed;
    top:0px;
    right:0px;
    bottom:0px;
    left:0px;
    z-index: 1
  }
  .overlay {
    transform: rotate(-90deg) scale(0.8,0.8) translate(29%, 0%);
    z-index: 10001;
    position: relative;
    width: 51%;

  }

  .mainScreen {
    z-index: 10000;
    transform: rotate(90deg);
    position:absolute;
    top:50%;
    right: 0%;
  }
  .iconsMedia {
    transform: rotate(-90deg);
  }

}

.close-button{
  float: right;
  top:-24px;
  right:-24px;
  background: none;
    border: none;
    font-weight: bold;
    font-size: larger;
}

.close-icon {
  transition: 1s ease-in-out;
}

.close-icon:hover {
  transform: rotate(180deg);
}
.imagesQR{
  max-width: 250px !important;
  max-height: 250px !important;
  width: auto;
  height: auto;
}

.mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--primary-color) !important;
}
.example-full-width{
  width: 80%;
}

@media (max-width: 600px) and (min-width: 400px) {
  div.cdk-overlay-pane {
    width: 50% !important;
  }
} 

@media (max-width: 600px) and (min-width: 400px) {
  div.cdk-overlay-pane {
    width: 50% !important;
  }
} 

@media (max-width: 399px) and (min-width: 330px) {
  div.cdk-overlay-pane {
    width: 57% !important;
  }
}

@media (max-width: 700px) {
  .text-link {
    font-size: 15px;
  }
}